import React, { useState, useEffect, useContext } from 'react';
import styles from '../App.module.scss';
import { useParams } from 'react-router-dom';
import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import Header from '../components/Header';
import { getStorage, ref, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import { doc, getDocs, collection, setDoc, addDoc, getDoc, query, where } from 'firebase/firestore';
import { db } from "..";
import RocketAnimation from '../components/RocketSpinAnimation';
import { formatDollarNumber } from '../Helpers/FormatNumbers';
import PieChartComponent from '../components/PieChart';
import transactionImage from '../assets/transactionImage.png';

function Explorer() {

  const navigate = useNavigate();

  const { ticker } = useParams(); //access the ticker from the react router

  const dispatch = useAppDispatch();

  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)
  const currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate)
  const isSmallScreen = window.innerWidth <= 870;
  const storage: FirebaseStorage = getStorage();
  const auth = getAuth();
  const currentUserID = auth.currentUser?.uid
  //const currentUserID = 'D0lCpz2WuoeYO6euWlx2eo5uLma2' Test
  const currentUserEmail = auth.currentUser?.email
  const [assetData, setAssetData] = useState<DigitalAsset | null>(null);
  const [assetIconLink, setIconLink] = useState('');
  const [selectedTab, setSelectedTab] = useState(1);
  const [holderCount, setHolderCount] = useState(1);
  const [chartData, setchartData] = useState<Record<string, number>>({}); 

  const getBitcoinPrice = async () => {
    if (bitcoinPriceInDollars > 0.0000001) {
        } else {
            try {
                const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
                const response = await fetchBTCPriceInUSD({});
    
                dispatch(setBitcoinPriceData(1.0/(response.data as number)));
                } catch (error) {
                console.error(error);
                }
        };
  };

  useEffect(() => {
    getBitcoinPrice();
  }, [bitcoinPriceInDollars]);

  interface DigitalAsset {
    Ticker: string;
    Name: string;
    Supply: number;
    OriginationDate: string;
    OriginatorUsername: string;
    isUsingCustomIcon: boolean;
    Price: number;
    Description: string;
    RecentlyViewedBy: [string];
    LikedBy: string[];
    OriginatorUID: string;
    [key: string]: any;
  };

  async function fetchAssetData(ticker: string): Promise<DigitalAsset | null> {
    try {
      const docRef = doc(db, 'cryptos', ticker); // Reference to the document
      const querySnapshot = await getDoc(docRef);
      if (querySnapshot.exists()) {
        const data = querySnapshot.data();
        const assetData: DigitalAsset = data as DigitalAsset;

        const emailCounts: Record<string, number> = {};

        // Assuming you want to process or log the additional email fields
        Object.keys(data).forEach(key => {
            if (data[key] && typeof data[key] === 'number' && key.includes('@')) {

                const normalizedKey = key.endsWith('ORDER') ? key.slice(0, -5) : key;
            
                // Check if the normalized email already exists in the object
                if (emailCounts[normalizedKey]) {
                    emailCounts[normalizedKey] += data[key]; // Increment by the value if already exists
                } else {
                    emailCounts[normalizedKey] = data[key]; // Initialize with the value if not already exists
                }


            }
        });

        setchartData(emailCounts)

        const totalHolders = Object.keys(emailCounts).length;
        setHolderCount(totalHolders);  // Set the holder count state

        return assetData;
      } else {
        console.error('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error retrieving data:', error);
      return null;
    }
  }

  const loadAssetData = async () => {
    // Ensure that ticker is not undefined before using it to fetch data
    if (ticker !== undefined) {
      const retrievedAssetData = await fetchAssetData(ticker);
      if (retrievedAssetData !== null) {
        setAssetData(retrievedAssetData);
      } else {
        console.log('No asset data retrieved');
        // Optionally set asset data to a default or error state
      }
    } else {
      console.error('Ticker is undefined');
      // Handle undefined ticker, for example, by showing an error message or setting an error state
    }
  };

  useEffect(() => {
    loadAssetData();
  }, [ticker]);
  
  useEffect(() => {
    const getCryptoIconLink = async () => {
      if (assetData && assetData.isUsingCustomIcon) {
        const storageRef = ref(storage, `cryptoIcons/${assetData.Ticker}.png`);
        try {
          const url = await getDownloadURL(storageRef);
          setIconLink(url.toString());
        } catch (error) {
          console.log(`Error getting custom icon for ${assetData.Ticker}:`, error);
          setIconLink('../assets/CryptoIcons/P.png');
        }
      } else if (assetData) {
        const firstChar = assetData!.Name.charAt(0);
        if (/^[A-Za-z]$/.test(firstChar)) {
            const linkString = `../assets/CryptoIcons/${firstChar.toUpperCase()}.png`;
            setIconLink(linkString);
        } else {
            const linkString = `../assets/CryptoIcons/X.png`;
            setIconLink(linkString);
        }
      }
    };
    getCryptoIconLink();

  }, [assetData]);

  const iconImageStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px',
    maxWidth: '50px',
    maxHeight: '50px',
    width: '50px',
    height: '50px',
    borderRadius: '50%', // Makes the element circular
    overflow: 'hidden' // Hides any overflowing content
};

  const iconImage = () => {
    const firstChar = assetData!.Name.charAt(0);
    //console.log('Testing first char')
    if (/^[^A-Za-z]+$/.test(firstChar)) {
      return (
        <div>
          <img
            style={iconImageStyle}
            src={
              assetData!.isUsingCustomIcon
                ? assetIconLink
                : require(`../assets/CryptoIcons/X.png`)
            }
            alt={` `}
          />
        </div>
      );
    } else {
        return (
          <img
          style={iconImageStyle}
            src={
              assetData!.isUsingCustomIcon
                ? assetIconLink
                : require(`../assets/CryptoIcons/${(assetData!.Name).charAt(0).toUpperCase()}.png`)
            }
            alt={` `}
          />
      );
    }
  };

  useEffect(() => {
    console.log('assetIconLink', assetIconLink)
    console.log('bitcoinPriceInDollars', bitcoinPriceInDollars)
    console.log('assetData', assetData)
  }, [assetIconLink, bitcoinPriceInDollars, assetData]);
  

  const renderDynamicFields = () => {
    const aggregatedEntries: Record<string, number> = {};

    // Collect and aggregate entries
    for (const key in assetData) {
        if (assetData.hasOwnProperty(key) && typeof assetData[key] === 'number' && key.includes('@')) {
            // Check if key ends with "ORDER" and strip it off
            const baseKey = key.endsWith('ORDER') ? key.slice(0, -5) : key;
            // Aggregate values on the base email
            if (aggregatedEntries[baseKey]) {
                aggregatedEntries[baseKey] += assetData[key];
            } else {
                aggregatedEntries[baseKey] = assetData[key];
            }
        }
    }

    // Convert aggregated object back to array and sort by value
    const entries = Object.keys(aggregatedEntries).map(key => ({
        key: key,
        value: aggregatedEntries[key]
    }));
    entries.sort((a, b) => b.value - a.value);

    // Create JSX elements for each sorted entry
    const fields = entries.map((entry, index) => (
        <div key={entry.key} style={{ display: 'flex', width: '100%', borderTop: '1px solid #777777', 
        paddingTop: '10px', paddingBottom: '10px' }}>
            <div style={{width: '4%'}}>{index+1}</div>
            <div style={{ width: '40%' }}>{entry.key}</div>
            <div style={{ width: '20%' }}>{entry.value.toLocaleString()}</div>
            <div style={{ width: '15%' }}>{((entry.value/(assetData!.Supply))*100).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 4 })}%</div>
            <div style={{ width: '20%' }}>{(entry.value*assetData!.Price*bitcoinPriceInDollars).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
        </div>
    ));

    return fields;
};


/*Transactions*/
const [transactionData, setTransactionData] = useState<Transaction[]>([]);

class Transaction {
    type: string;
    time: string;
    amount: number;
    receiverEmail: string;
    senderEmail: string;
    iconURL: string;
    cryptoIsUsingCustomIcon: boolean;
    AssetSent: string;

    constructor(
        type: string,
        time: string,
        amount: number,
        receiverEmail: string,
        senderEmail: string,
        iconURL: string,
        cryptoIsUsingCustomIcon: boolean,
        AssetSent: string,
    ) {
      this.type = type;
      this.time= time;
      this.amount = amount;
      this.receiverEmail = receiverEmail;
      this.senderEmail = senderEmail;
      this.iconURL = iconURL;
      this.cryptoIsUsingCustomIcon = cryptoIsUsingCustomIcon;
      this.AssetSent = AssetSent
    }
  }

  useEffect(() => {
    const getTransactionData = async () => {
      const querySnapshot = await getDocs(collection(db, "transactions"));
      const transactions = await Promise.all(querySnapshot.docs.map(doc => {
        const { AmountSent, AssetSent, ReceiverEmail, SenderEmail, TransactionTime, 
            Type } = doc.data();
        let iconUrl = "../../assets/CryptoIcons/BTC.png";

        // Fallback date in case of parsing errors
        const validDate = new Date(TransactionTime);
        if (isNaN(validDate.getTime())) {
          console.error(`Invalid date detected for transaction ID ${doc.id}:`, TransactionTime);
          // Optionally use a fallback date such as the current time
          // validDate = new Date();
        }

        return {
          type: Type,
          time: safeDateConversion(TransactionTime),
          amount: AmountSent,
          receiverEmail: ReceiverEmail,
          senderEmail: SenderEmail,
          iconURL: iconUrl,
          assetSent: AssetSent,
          cryptoIsUsingCustomIcon: false,
          AssetSent: AssetSent
        };
      }));

      setTransactionData(
        transactions
          .filter(transaction => 
            transaction.AssetSent === ticker
          )
          .sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())
      );
    };

    function safeDateConversion(dateStr: string) {
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) {
          console.error("Invalid date detected:", dateStr);
          return new Date().toISOString(); // or return a default date or handle differently
        }
        return date.toISOString();
      }

    getTransactionData();
  }, [db, ticker, bitcoinPriceInDollars]);

  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };
  
    return (
      
      <div className={styles.App} style={{ backgroundColor: '#222222' }}>
        <div className={styles['dark-gray-background']}>
            <Header isTradePage = {true}/>
        </div>
        <div className={styles['darker-gray-background']} style={{paddingTop: '80px'}}>

        {(assetData && bitcoinPriceInDollars && assetIconLink) ? (
            <div>
                {currentUserID == assetData?.OriginatorUID ? (

                    <div>
                        <div style={{marginLeft: '40px', paddingTop: '40px'}}>
                            <div style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center'}}>
                            {iconImage()}
                            <div style={{color: 'white', fontSize: '40px'}}>{assetData.Name}</div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', 
                            padding: '0 20px', marginTop: '40px', color: 'white' }}>
                                <div style={{ backgroundColor: '#222222', 
                                borderRadius: '10px', flex: 1, margin: '0 10px', height: '280px' }}>

                                    <div style={{paddingLeft: '20px'}}>
                                    <div style={{ paddingTop: '20px', fontSize: '25px', fontWeight: 'bold'}}>
                                        Market Overview
                                    </div>

                                    <div style={{display: 'flex', paddingTop: '20px',}}>
                                        <div style={{textAlign: 'left', width: '50%'}}>Price</div>
                                        <div style={{textAlign: 'left', width: '200px'}}>${formatDollarNumber(assetData.Price*bitcoinPriceInDollars)}</div>
                                    </div>

                                    <div style={{display: 'flex', paddingTop: '20px',}}>
                                        <div style={{textAlign: 'left', width: '50%'}}>Market Cap</div>
                                        <div style={{textAlign: 'left', width: '200px'}}>${formatDollarNumber(assetData.Price*bitcoinPriceInDollars*assetData.Supply)}</div>
                                    </div>

                                    <div style={{display: 'flex', paddingTop: '20px',}}>
                                        <div style={{textAlign: 'left', width: '50%'}}>Total Supply</div>
                                        <div style={{textAlign: 'left', width: '200px'}}>{formatDollarNumber(assetData.Supply)}</div>
                                    </div>

                                    <div style={{display: 'flex', paddingTop: '20px',}}>
                                        <div style={{textAlign: 'left', width: '50%'}}>Holders</div>
                                        <div style={{textAlign: 'left', width: '200px'}}>{holderCount.toLocaleString()}</div>
                                    </div>


                                    </div>
                                </div>
                                <div style={{ backgroundColor: '#222222', 
                                borderRadius: '10px', flex: 1, margin: '0 10px', height: '280px' }}>
                                    <div style={{paddingLeft: '20px'}}>
                                   <div style={{paddingTop: '20px', fontSize: '25px', fontWeight: 'bold'}}>
                                        Profile Sumary
                                    </div>

                                    <div style={{display: 'flex', paddingTop: '20px',}}>
                                        <div style={{textAlign: 'left', width: '40%'}}>Unique ID</div>
                                        <div style={{textAlign: 'left', width: '200px'}}>{ticker}</div>
                                    </div>

                                    <div style={{display: 'flex', paddingTop: '20px',}}>
                                        <div style={{textAlign: 'left', width: '40%'}}>Trading Link</div>
                                        <div style={{textAlign: 'left', width: '200px'}}>https://finfriend.com/trade/{ticker}</div>
                                    </div>

                                    <div style={{display: 'flex', paddingTop: '20px',}}>
                                        <div style={{textAlign: 'left', width: '40%'}}>Decimals</div>
                                        <div style={{textAlign: 'left', width: '200px'}}>8</div>
                                    </div>



                                    <div style={{display: 'flex', paddingTop: '20px',}}>
                                        <div style={{textAlign: 'left', width: '40%'}}>Created On</div>
                                        <div style={{textAlign: 'left', width: '200px'}}>{assetData.OriginationDate}</div>
                                    </div>

                                    </div>
                                </div>
                            </div>


                            <div style={{marginTop: '40px'}}>

                                <div style={{marginLeft: '30px', marginRight: '30px', display: 'flex', width: '450px'}}>
                            <div 
          onClick={() => setSelectedTab(1)} 
          style={{ 
            flex: 1, 
            textAlign: 'center', 
            cursor: 'pointer', 
            padding: '10px', 
            color: selectedTab === 1 ? '#FFA14A' : 'white',
            backgroundColor: selectedTab === 1 ? '#222222' : '#1a1a1a', 
            borderRadius: selectedTab === 1 ? '10px 10px 0 0' : '0',
            fontWeight: 'bold', fontSize: '25px'
          }}
        >
          Transactions
        </div>
        <div 
          onClick={() => setSelectedTab(2)} 
          style={{ 
            flex: 1, 
            textAlign: 'center', 
            cursor: 'pointer', 
            padding: '10px', 
            color: selectedTab === 2 ? '#FFA14A' : 'white',
            backgroundColor: selectedTab === 2 ? '#222222' : '#1a1a1a',
            borderRadius: selectedTab === 2 ? '10px 10px 0 0' : '0',
            fontWeight: 'bold', fontSize: '25px'
          }}
        >
          Holders
        </div>

        

        <div 
          onClick={() => setSelectedTab(3)} 
          style={{ 
            flex: 1, 
            textAlign: 'center', 
            cursor: 'pointer', 
            padding: '10px', 
            color: selectedTab === 3 ? '#FFA14A' : 'white',
            backgroundColor: selectedTab === 3 ? '#222222' : '#1a1a1a',
            borderRadius: selectedTab === 3 ? '10px 10px 0 0' : '0',
            fontWeight: 'bold', fontSize: '25px'
          }}
        >
          Analysis
        </div>

        </div>
        
                            <div style={{
                                
                                backgroundColor: '#222222', 
                                borderRadius: selectedTab === 1 ? '0px 10px 10px 10px' : '10px', 
                                marginLeft: '30px', 
                                marginRight: '30px', 
                                paddingTop: '10px'
                            }}>




<div style={{ display: 'flex' }}>
      </div>

      {/* Tab Content */}
      <div style={{ padding: '20px', color: 'white', minHeight: '300px' }}>
        {selectedTab === 1 && (
        <div>

        {transactionData && bitcoinPriceInDollars && currentUserEmail && transactionData.length > 0 && (

        <div style={{display: 'flex', width: '100%', fontWeight: 'bold', paddingBottom: '10px'}}> 
            <div style={{width: '20%'}}>Time</div>
            <div style={{width: '25%'}}>From</div>
            <div style={{width: '25%'}}>To</div>
            <div style={{width: '15%'}}>Amount</div>
            <div style={{width: '15%'}}>Value</div>
        </div>
        )}
          <div>

            


          <div>
            {transactionData && bitcoinPriceInDollars && currentUserEmail && transactionData.length > 0 ? (
                transactionData.map((transaction, index) => (
                <div key={index}>
                    <div style={{display: 'flex', width: '100%', paddingBottom: '10px', 
                    paddingTop: '10px', borderTop: '1px solid #777777', }}> 
                        <div style={{width: '20%'}}>
                            <div>
                                {new Date(transaction.time).toLocaleString('en-US', {
                                    year: 'numeric', 
                                    month: 'long', 
                                    day: 'numeric', 
                                    hour: '2-digit', 
                                    minute: '2-digit', 
                                    hour12: true
                                })}
                                </div>
                        </div>
                        <div style={{width: '25%'}}>{transaction.senderEmail}</div>
                        <div style={{width: '25%'}}>{transaction.receiverEmail}</div>
                        <div style={{width: '15%'}}>{(transaction.amount).toLocaleString()}</div>
                        <div style={{width: '15%'}}>{(transaction.amount * assetData.Price * bitcoinPriceInDollars).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                    </div>
                </div>
                ))
            ) : (
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '50px', gap: '20px'}}>
                <img src={transactionImage} style={{width: '100px', height: '100px', opacity: '0.6'}}>
                </img>
                <div style={{color: '#777777'}}>No Transactions Yet</div> 
                </div>
            )}
            </div>
            
          </div>
          </div>
        )}

        {selectedTab === 2 && (
        <div>
        <div style={{display: 'flex', width: '100%', fontWeight: 'bold', paddingBottom: '10px'}}> 
            <div style={{width: '4%'}}>#</div>
            <div style={{width: '40%'}}>Email</div>
            <div style={{width: '20%'}}>Quantity</div>
            <div style={{width: '15%'}}>Percentage</div>
            <div style={{width: '20%'}}>Value</div>
        </div>

          <div>{renderDynamicFields()}</div>
          </div>
        )}

        {selectedTab === 3 && (

            <div>
                
          <PieChartComponent chartData={chartData} totalSupply={assetData.Supply}/>
          </div>
        )}

      </div>
      </div>
                            </div>
                    </div>
                ) : (
                    <div>
                        <div style={{color: 'white', height: '100vh'}}>
                            <div style={{paddingTop: '200px', textAlign: 'center'}}>
                            The creator of this digital asset has not made this information public
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div style={{backgroundColor: '#1a1a1a', height: '100vh'}}>
                <div style={{ backgroundColor: '#1a1a1a', marginTop: '100px' }}>
                <RocketAnimation loading={true} type={'large'}/>
                </div>
            </div>
        )}

        </div>
        <div style={{paddingTop: '140px', backgroundColor: '#1a1a1a'}}>
        <Footer />
        </div>
      </div>
    );
}


  export default Explorer;