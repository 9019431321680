import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { functions, db } from "../..";
import { httpsCallable } from "firebase/functions";
import { doc, getDocs, collection } from 'firebase/firestore';
import { useAppSelector } from '../../redux/hooks';
import { getAuth } from "firebase/auth";
import { Link } from 'react-router-dom';
import { getVolumeData } from '../../Helpers/getVolumeData';
import { formatDollarNumber } from '../../Helpers/FormatNumbers';
import TestTransactionImage from '../../assets/TestTransaction.png';
import RecevieImage from '../../assets/ReceiveTransaction.png';
import SendImage from '../../assets/SendTransaction.png';
import transactionImage from '../../assets/transactionImage.png';

function TransactionsTile() {

  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)

  const [loading, setLoading] = useState(true);
  const [transactionData, setTransactionData] = useState<Transaction[]>([]);

  const auth = getAuth();
  const currentUserEmail = auth.currentUser?.email
  const currentUserID = auth.currentUser?.uid
  //const currentUserEmail = 'stackpackmedia@gmail.com' Test
  //const currentUserID = 'D0lCpz2WuoeYO6euWlx2eo5uLma2' Test

  class Transaction {
    type: string;
    time: string;
    amount: number;
    receiverEmail: string;
    senderEmail: string;
    receiver: string;
    sender: string;
    iconURL: string;
    cryptoIsUsingCustomIcon: boolean;
    AssetSent: string;
    Status: string;

    constructor(
        type: string,
        time: string,
        amount: number,
        receiverEmail: string,
        senderEmail: string,
        receiver: string,
        sender: string,
        iconURL: string,
        cryptoIsUsingCustomIcon: boolean,
        AssetSent: string,
        Status: string,
    ) {
      this.type = type;
      this.time= time;
      this.amount = amount;
      this.receiverEmail = receiverEmail;
      this.senderEmail = senderEmail;
      this.receiver = receiver;
      this.sender = sender;
      this.iconURL = iconURL;
      this.cryptoIsUsingCustomIcon = cryptoIsUsingCustomIcon;
      this.AssetSent = AssetSent
      this.Status = Status
    }
  }
      

  useEffect(() => {
    const getTransactionData = async () => {
      const querySnapshot = await getDocs(collection(db, "transactions"));
      const transactions = await Promise.all(querySnapshot.docs.map(doc => {
        const { AmountSent, AssetSent, ReceiverEmail, SenderEmail, TransactionTime, 
            Type, Receiver, Sender, Status } = doc.data();
        let iconUrl = "../../assets/CryptoIcons/BTC.png";

        // Fallback date in case of parsing errors
        const validDate = new Date(TransactionTime);
        if (isNaN(validDate.getTime())) {
          console.error(`Invalid date detected for transaction ID ${doc.id}:`, TransactionTime);
          // Optionally use a fallback date such as the current time
          // validDate = new Date();
        }

        return {
          type: Type,
          time: safeDateConversion(TransactionTime),
          amount: AmountSent,
          receiverEmail: ReceiverEmail,
          senderEmail: SenderEmail,
          receiver: Receiver ? Receiver : '',
          sender: Sender ? Sender : '',
          iconURL: iconUrl,
          assetSent: AssetSent,
          cryptoIsUsingCustomIcon: false,
          AssetSent: AssetSent,
          Status: Status ? Status : ''
        };
      }));

      setTransactionData(
        transactions
          .filter(transaction => 
            transaction.senderEmail === currentUserEmail || 
            transaction.receiverEmail === currentUserEmail
          )
          .sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())
      );

      setLoading(false);
    };

    getTransactionData();
  }, [db, currentUserEmail, bitcoinPriceInDollars]);


  function safeDateConversion(dateStr: string) {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      console.error("Invalid date detected:", dateStr);
      return new Date().toISOString(); // or return a default date or handle differently
    }
    return date.toISOString();
  }
  

  
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ color: 'white', margin: '4px', fontSize: '26px' }}>Transactions</div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '12px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ color: 'white' }}>&nbsp;&nbsp;&nbsp;</div>
        </div>
      </div>

      {loading ? (
        <div></div>
      ) : (
        <div>
          {transactionData && bitcoinPriceInDollars && transactionData.length > 0 ? (
            <div>
            </div>
          ) : null}

          <div>
            {transactionData && bitcoinPriceInDollars && currentUserEmail && transactionData.length > 0 ? (
              transactionData.map((transaction, index) => (
                <div key={index} className={styles['asset-crypto-list-container']}>


<div style={{display: 'flex', alignItems: 'center'}}>
{transaction.receiverEmail == transaction.senderEmail && ( 
    <img style={{width: '35px', height: 'auto'}} src={TestTransactionImage} alt="" />
)}


{transaction.receiverEmail != transaction.senderEmail && ( 
    <>
                        {transaction.receiverEmail == currentUserEmail && (
                            <img style={{width: '35px', height: 'auto'}} src={RecevieImage} alt="" />
                        )}
                        {transaction.senderEmail == currentUserEmail && (
                            <img style={{width: '35px', height: 'auto'}} src={SendImage} alt="" />
                        )}
                        </>
                    )}

<div style={{display: 'flex', textAlign: 'left', flexDirection: 'column', marginLeft: '10px', gap: '10px'}}>

                    <div style={{fontSize: '20px', fontWeight: 'bold'}}>



                    {transaction.type == 'userSentBitcoinOnChain' && (
                            <>
                                {transaction.senderEmail == currentUserEmail && (
                                    <div>Sent {transaction.AssetSent} <span style={{ color: '#666666' }}>On Chain</span></div>
                                )}

                                {transaction.receiverEmail == currentUserEmail && (
                                    <div>Received {transaction.AssetSent} <span style={{ color: '#666666' }}>On Chain</span></div>
                                )}
                                
                            </>
                            
                        )}


                        {transaction.type == 'userSent' && (
                            <>
                            {transaction.receiverEmail == transaction.senderEmail ? ( 

                                <div>Sent {transaction.AssetSent} <span style={{ color: '#666666' }}>To Self</span></div>
                                
                            ) : (
                                <>
                                {transaction.senderEmail == currentUserEmail && (
                                    <div>Sent {transaction.AssetSent}</div>
                                )}

                                {transaction.receiverEmail == currentUserEmail && (
                                    <div>Received {transaction.AssetSent}</div>
                                )}
                                
                                </>
                            )}
                            </>
                            
                        )}

                        {transaction.type == 'userPurchasedBitcoin' && (
                            <>

                                {transaction.senderEmail == currentUserEmail && (
                                    <div>Sent {transaction.AssetSent}</div>
                                )}

                                {transaction.receiverEmail == currentUserEmail && (
                                    <div>Received {transaction.AssetSent}</div>
                                )}

                            </>
                        )}

                        {transaction.type == 'createNewAssetPurchase' && (
                            <>
                            <div>Sent {transaction.AssetSent}</div>
                            </>
                        )}

                    </div>

                    <div>
                    {new Date(transaction.time).toLocaleString('en-US', {
                        year: 'numeric', 
                        month: 'long', 
                        day: 'numeric', 
                        hour: '2-digit', 
                        minute: '2-digit', 
                        hour12: true
                    })}
                    </div>
                    </div>
</div>


<div style={{width: '320px'}}>


                        {transaction.type == 'userSentBitcoinOnChain' && (
                            <>
                                {transaction.sender == currentUserID && (
                                  <>
                                    <div>{transaction.receiver}</div>
                                    {transaction.Status == 'PENDING' && (
                                      <div style={{color: '#777777'}}>Pending</div>
                                    )}
                                    
                                    </>
                                )}

                                {transaction.receiver == currentUserID && (
                                    <div>{transaction.sender}</div>
                                )}
                                
                            </>
                            
                        )}

                    {transaction.receiverEmail === currentUserEmail && 
                    (
                    transaction.senderEmail === 'finfriend@finfriend.com' ? (
                        <p style={{textAlign: 'left'}}>
                          {transaction.type == 'userPurchasedBitcoin' && (
                          <div style={{color: '#999999'}}>Bought Bitcoin</div>)}
                        </p>
                    ) : (
                        <p style={{textAlign: 'left'}}>{transaction.senderEmail}</p>)
                    )}
                    {transaction.senderEmail === currentUserEmail && (
                        transaction.receiverEmail === 'finfriend@finfriend.com' ? (
                        <p style={{textAlign: 'left'}}>
                          {transaction.type == 'createNewAssetPurchase' && (
                          <div style={{color: '#999999'}}>Paid For A New Asset, Thank You!</div>)}
                          {transaction.type == 'userPurchasedBitcoin' && (
                          <div style={{color: '#999999'}}>Bought Bitcoin</div>)}
                        </p>
                    ) : (
                        <p style={{textAlign: 'left'}}>{transaction.receiverEmail}</p>)
                    )}
                  </div>

                  <div style={{width: '150px'}}>
                  <p style={{textAlign: 'left', fontSize: '25px'}}>{formatDollarNumber(transaction.amount)} {transaction.AssetSent}</p>
                  </div>
                </div>

              ))
            ) : (
              <div style={{ color: 'white', margin: '20px', fontSize: '45px', textAlign: 'center', maxWidth: '500px' }}>
                <img src={transactionImage} alt="Close" style={{ width: '84px', height: '84px' }}></img>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default TransactionsTile;