import React, { useState, useRef, useEffect } from 'react';
import RocketHittingMoon from '../assets/rocketHittingMoon.png';
import FinFriendRocketMoon from '../assets/FinFriendRocketMoon.png';
import appleIcon from '../assets/appleIcon.png';
import googlePlayIcon from '../assets/googlePlayIcon.png';
import styles from '../App.module.scss';
import finfriendDesktopImage from '../assets/finfriendDesktop.png';
import lapTopImage from '../assets/lapTopImage.png';
import appHomeImage from '../assets/AppHomeImage.png';
import appCreateImage from '../assets/AppCreateImage.png';
import appTradeImage from '../assets/AppTradeImage.png';
import Footer from './Footer';

export default function SmallScreenLanding() {


  const [androidClicked, setandroidClicked] = useState(false);

  const [homeImageSelected, sethomeImageSelected] = useState(false);
const [tradeImageSelected, settradeImageSelected] = useState(false);
const [createImageSelected, setcreateImageSelected] = useState(true);

const isTallScreen = window.innerHeight >= 690;

const [tallScreenCalculated, setTallScreenCalculated] = useState(false);

useEffect(() => {
  setTallScreenCalculated(true)
}, [isTallScreen]);

const handleHomeImageClicked = () => {
  if (!homeImageSelected) {
    sethomeImageSelected(true)
    setcreateImageSelected(false)
    settradeImageSelected(false)
  }
};

const handleCreateImageClicked = () => {
if (!createImageSelected) {
  setcreateImageSelected(true)
  sethomeImageSelected(false)
  settradeImageSelected(false)
}
};

const handleTradeImageClicked = () => {
if (!tradeImageSelected) {
  settradeImageSelected(true)
  sethomeImageSelected(false)
  setcreateImageSelected(false)
}
};

const [stars, setStars] = useState<Star[]>([]);

  type Star = {
    left: number;
    top: number;
    size: number;
  };

  useEffect(() => {
    // Initialize the stars
    const initialStars = Array.from({ length: 300 }).map(() => ({
      left: Math.random() * window.innerWidth,
      top: Math.random() * (window.innerHeight+90), // adding some constant because the browser has some bar that is messing it up
      size: Math.random() * 3
    }));
    setStars(initialStars);
  
    // Function to update the position of a random star
    const moveRandomStar = () => {
      // Use the current state of stars
      setStars((currentStars) => {
        const updatedStars = [...currentStars];
  
        const numOfStarsToMove = Math.floor(Math.random() * 5) + 1; // Move between 1 to 10 stars
  
        for (let i = 0; i < numOfStarsToMove; i++) {
          const starIndex = Math.floor(Math.random() * updatedStars.length);
          const randomStar = updatedStars[starIndex];
  
          // Calculate new random positions
          const newLeft = randomStar.left + (Math.random() * 80 - 40);
          const newTop = randomStar.top + (Math.random() * 80 - 40);
  
          updatedStars[starIndex] = {
            ...randomStar,
            left: newLeft,
            top: newTop
          };
        }
  
        return updatedStars;
      });
    }
  
    const interval = setInterval(moveRandomStar, 500); // move a set of random stars every 0.5 seconds
  
    return () => clearInterval(interval);
  }, []);
  
  

  
  

    const [isIOSHovered, setIOSIsHovered] = useState(false);
    const [isAndroidHovered, setAndroidIsHovered] = useState(false);

    const handleIOSMouseEnter = () => {
        setIOSIsHovered(true);
    };

    const handleIOSMouseLeave = () => {
        setIOSIsHovered(false);
    };

    const handleAndroidMouseEnter = () => {
        setAndroidIsHovered(true);
    };

    const handleAndroidMouseLeave = () => {
        setAndroidIsHovered(false);
    };
  
    const handleAndroidClicked = () => {
      setandroidClicked(true);
  };


    return (

      <div>
      <div className={styles['stars-background']}
           style={{ height: '100vh', width: '100vw',}}>
    
        <div style={{ position: 'relative', height: '100vh' }}>
          {stars.map((star, index) => (
            <div
              key={index}
              className={styles["star"]}
              style={{
                position: 'absolute',
                left: `${star.left}px`,
                top: `${star.top}px`,
                width: `${star.size}px`,
                height: `${star.size}px`,
                background: 'white',
                borderRadius: '50%',
                zIndex: -1,
              }}
            />
          ))}
    
    <div style={{textShadow: '10px 10px 10px #1a1a1a, -10px -10px 10px #1a1a1a, 10px -10px 10px black, -10px 10px 10px #1a1a1a'}}>
          <div style={{ color: 'white', textAlign: 'center', fontSize: '32px', 
                        paddingBottom: '20px', paddingTop: '35px', }}>
            Create A Crypto
          </div>
          </div>
    
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '30px',
              height: '65%' 
            }}
          >
            
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img 
                src={FinFriendRocketMoon} 
                alt="Rocket Hitting Moon" 
                style={{ height: '220px', width: 'auto' }} 
              />
            </div>

{/*         <a 
    href="https://play.google.com/store/apps/details?id=com.finfriend" 
    target="_blank" 
    rel="noopener noreferrer" 
    style={{ textDecoration: 'none', marginTop: '0px'  }}  // This gets rid of the underline
>*/}

{/* 
{androidClicked ? <div>

<div style={{textAlign: 'center', color: 'white', fontSize: '20px', background: '#1a1a1a'}}>Android Temporarily Unavailable</div>
<div style={{textAlign: 'center', color: 'white', fontSize: '16px', background: '#1a1a1a', marginTop: '7px'}}>Don't worry! You can always go to<br/>FinFriend.com on your dektop browser.</div>

</div> : <div>
  
  
<div
        style={{
            backgroundColor: isAndroidHovered ? 'white' : '#1a1a1a',
            color: isAndroidHovered ? '#1a1a1a' : 'white',
            display: 'flex',
            justifyContent: 'center',  // Horizontally centers the content
            alignItems: 'center',  // Vertically centers the content
            padding: '10px 5px',
            fontSize: '25px',
            fontWeight: 'bold',
            textAlign: 'center',
            borderRadius: '10px',
            cursor: 'pointer',
            width: '220px',
        }}
        onMouseEnter={handleAndroidMouseEnter}
        onMouseLeave={handleAndroidMouseLeave}
        onClick={handleAndroidClicked}
    >
        <div style={{ display: 'flex', alignItems: 'center'}}>
            <img
                src={googlePlayIcon}
                alt="Google Play Icon"
                style={{
                    width: '40px', 
                    height: '40px',
                    filter: isAndroidHovered ? 'invert(1)' : 'none',
                    marginRight: '10px'
                }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                <div style={{ fontSize: '12px' }}>GET IT ON </div>
                Google Play
            </div>
        </div>
    </div>

  </div>} */}



        <a href="https://apps.apple.com/us/app/finfriend/id6446862715" 
    target="_blank" 
    rel="noopener noreferrer" 
    style={{ textDecoration: 'none' }}  // This gets rid of the underline
>
    <div
        style={{
            backgroundColor: isIOSHovered ? 'white' : '#1a1a1a',
            color: isIOSHovered ? '#1a1a1a' : 'white',
            display: 'flex',
            justifyContent: 'center',  // Horizontally centers the content
            alignItems: 'center',  // Vertically centers the content
            padding: '10px 5px',
            fontSize: '25px',
            fontWeight: 'bold',
            textAlign: 'center',
            borderRadius: '10px',
            cursor: 'pointer',
            width: '220px',
        }}
        onMouseEnter={handleIOSMouseEnter}
        onMouseLeave={handleIOSMouseLeave}
    >
        <div style={{ display: 'flex', alignItems: 'center'  }}>
            <img
                src={appleIcon}
                alt="Google Play Icon"
                style={{
                    width: '40px', 
                    height: '40px',
                    filter: isIOSHovered ? 'invert(1)' : 'none',
                    marginRight: '10px'
                }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                <div style={{ fontSize: '12px' }}>GET IT ON </div>
                App Store
            </div>
        </div>
    </div>
</a>
</div>
      </div>
    </div>








    <div style={{backgroundColor: '#1a1a1a', height: '112vh', 
    width: '100vw', color: 'white', fontSize: '30px', 
     }}>

     <div style={{display: 'flex', flexDirection: 'column', 
     alignItems: 'center', paddingTop: '9vh', gap: '4vh'}}>


     <div style={{color:'white', background: '#1a1a1a',
fontWeight: 'bold', fontSize: '25px', textAlign: 'center'}}>The Friend that helps <br/> you finance.</div>

<div>


<div style={{ position: 'relative', width: 'auto', height: '370px',
display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
  <img
    src={appTradeImage}
    alt="Trade"
    style={{
      position: 'absolute',
      width: 'auto',
      height: '370px',
      border: '2px solid #444444',
      borderRadius: '20px',
      opacity: tradeImageSelected ? 1 : 0,
    }}
  />
  <img
    src={appHomeImage}
    alt="Home"
    style={{
      position: 'absolute',
      width: 'auto',
      height: '370px',
      border: '2px solid #444444',
      borderRadius: '20px',
      opacity: homeImageSelected ? 1 : 0,
    }}
  />
  <img
    src={appCreateImage}
    alt="Create"
    style={{
      position: 'absolute',
      width: 'auto',
      height: '370px',
      border: '2px solid #444444',
      borderRadius: '20px',
      opacity: createImageSelected ? 1 : 0,
    }}
  />
</div>

</div>

<div style={{display: 'flex', height: '30px', 
alignItems: 'center', justifyContent: 'space-between', gap: '15px'}}> 
<div style={{fontSize: '25px', cursor: 'pointer', 
color: createImageSelected ? '#FFA14A' : 'white',}}
onClick={handleCreateImageClicked}>Create</div>

<div style={{color: 'white', fontSize: '30px'}}>|</div>
<div style={{fontSize: '25px', cursor: 'pointer', 
color: tradeImageSelected ? '#FFA14A' : 'white',}}
onClick={handleTradeImageClicked}>Trade</div>
<div style={{color: 'white', fontSize: '30px'}}>|</div>
<div style={{fontSize: '25px', cursor: 'pointer', 
color: homeImageSelected ? '#FFA14A' : 'white'}}
onClick={handleHomeImageClicked}>Explore</div>
</div>
</div>


    </div>









    <div style={{backgroundColor: '#1a1a1a', height: '100vh', 
    width: '100vw', color: 'white', fontSize: '30px', 
     }}>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',
     justifyContent: 'space-around', paddingTop: '25vh', height: '40vh'}}>
                <div >We're On Desktop Too</div>

{/*
<img 
  src={finfriendDesktopImage} 
  style={{
    width: '100vw', 
    height: 'auto', 
    marginTop: '40px', 
    borderTop: '2px solid #444444',
    borderBottom: '2px solid #444444',
  }}
/>
*/}

<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
<img 
src = {lapTopImage}
style={{
  width: '70px', 
  height: 'auto', 
  marginTop: '40px', 
}}
/>

<div style={{color: 'white', fontSize: '30px', }}> FinFriend.com</div>
</div>
</div>


    </div>


    <Footer/>
    </div>
  );
};
    