import React, { useState } from 'react';
import styles from '../App.module.scss';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import closeButton from '../assets/closeButton.png'
import appleIcon from '../assets/appleIcon.png';
import googlePlayIcon from '../assets/googlePlayIcon.png';
import qrCode from '../assets/finfriendQRCode.png';

export default function DownloadModal() {

    const [showDownloadModal, setDownloadModal] = useState(false);


    const [isDownloadHovered, setisDownloadHovered] = useState(false);
    const handleDownloadEnter = () => {
      setisDownloadHovered(true);
    };
    const handleDownloadLeave = () => {
      setisDownloadHovered(false);
    };

    const toggleDownloadModal = () => {
        setDownloadModal(!showDownloadModal);
      };

    const [isIOSHovered, setIOSIsHovered] = useState(false);
    const [isAndroidHovered, setAndroidIsHovered] = useState(false);

    const handleIOSMouseEnter = () => {
        setIOSIsHovered(true);
    };

    const handleIOSMouseLeave = () => {
        setIOSIsHovered(false);
    };

    const handleAndroidMouseEnter = () => {
        setAndroidIsHovered(true);
    };

    const handleAndroidMouseLeave = () => {
        setAndroidIsHovered(false);
    };
      const [errorMessage, setErrorMessage] = useState('');

    return (


        <div>

            <a style={{
                  marginRight: '20px',
                  fontSize: '22px',
                  color: isDownloadHovered ? 'orange' : 'white',
                  textDecoration: 'none',
                  cursor: 'pointer'
              }} onClick={toggleDownloadModal}
                onMouseEnter={handleDownloadEnter}
                onMouseLeave={handleDownloadLeave}>Download</a>

        {showDownloadModal && (
            <div >
              <div className={styles['overlay']} onClick={toggleDownloadModal}></div>
              <div className={styles['crypto-modal']} style={{height: '70vh'}}>
                <div className={styles['crypto-modal-header']}>
                  <h3 style={{textAlign: 'center', lineHeight: '1.5', fontSize: '35px'}}>FinFriend<br></br>From Anywhere</h3>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleDownloadModal} style={{width: '24px', height: '24px'}}></img>
                </div>
                <div className={styles['crypto-modal-content']} style= {{ display: 'flex', flexDirection: 'column', 
                    alignItems: 'center', gap: '30px',}}>

<div style={{width: "300px", display: "flex", justifyContent: "center"}}>


<div style={{background: '#222222', display: 'flex', justifyContent: 'center', 
alignItems: 'center', gap: '20px', flexDirection: 'column', paddingBottom: '5vh'}}>
      <img
        src={qrCode}
        alt="qrCode"
        style={{
          width: '200px',
          height: '200px',
          border: '1.5px solid #444444',
          borderRadius: '20px'
        }}
      />
      <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
      <div style={{color: '#BBBBBB'}}>Scan to dowload the App</div>
      <div style={{color: 'white', fontSize: '25px', textAlign: 'center'}}>iOS</div>
      </div>

        </div>

</div>

                </div>

              </div>
            </div>
          )}

    </div>
    )};