import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import CreateCryptoModals from '../components/CreateCryptoModals';
import HeaderNav from '../components/Authentication/HeaderNav';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import HeaderLeft from '../components/headerLeft';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import { useParams } from 'react-router-dom';
import { AffiliateLinkTracker } from '../Helpers/AffiliateLinkTracker';
import BitcoinIcon from '../assets/CryptoIcons/BTC.png';
import qrCode from '../assets/finfriendQRCode.png';
import appHomeImage from '../assets/AppHomeImage.png';
import appCreateImage from '../assets/AppCreateImage.png';
import appTradeImage from '../assets/AppTradeImage.png';
import appleIcon from '../assets/appleIcon.png';
import googlePlayIcon from '../assets/googlePlayIcon.png';
import Header from '../components/Header';
import { useSelector } from 'react-redux';
import BrowseCryptos from './BrowseCryptos';
import BTCicon from '../assets/CryptoIcons/BTC.png';
import HomePadeDynamic from '../components/HomePageDynamic';

function Home() {

  const { affiliate } = useParams();

  const navigate = useNavigate();

  const [isIOSHovered, setIOSIsHovered] = useState(false);
  const handleIOSMouseEnter = () => {
    setIOSIsHovered(true);
};
const handleIOSMouseLeave = () => {
    setIOSIsHovered(false);
};

const [isAndroidHovered, setAndroidIsHovered] = useState(false);
const handleAndroidMouseEnter = () => {
  setAndroidIsHovered(true);
};
const handleAndroidMouseLeave = () => {
  setAndroidIsHovered(false);
};



const [homeImageSelected, sethomeImageSelected] = useState(false);
const [tradeImageSelected, settradeImageSelected] = useState(false);
const [createImageSelected, setcreateImageSelected] = useState(true);
const [opacity, setOpacity] = useState(1);  // Initial font size
let currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate);
const isSmallScreen = window.innerWidth <= 800;
const dispatch = useAppDispatch();

  useEffect(() => {
    const handleScroll = () => {
      // Calculate new opacity based on scroll position (adjust logic as needed)
      let newOpacity = 1 - window.scrollY * 0.004;  // decreases by 0.01 for every 1px of scroll

      // Ensure opacity doesn't go below 0
      newOpacity = Math.max(newOpacity, 0);

      setOpacity(newOpacity);
    };

    // Add the event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
}, []);

useEffect(() => {
  if (affiliate) {
    AffiliateLinkTracker(affiliate);
  }
}, [affiliate]);


  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {

      
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);




  const handleHomeImageClicked = () => {
    if (!homeImageSelected) {
      sethomeImageSelected(true)
      setcreateImageSelected(false)
      settradeImageSelected(false)
    }
};

const handleCreateImageClicked = () => {
  if (!createImageSelected) {
    setcreateImageSelected(true)
    sethomeImageSelected(false)
    settradeImageSelected(false)
  }
};

const handleTradeImageClicked = () => {
  if (!tradeImageSelected) {
    settradeImageSelected(true)
    sethomeImageSelected(false)
    setcreateImageSelected(false)
  }
};


const isAnimationActive = useAppSelector((state) => state.animationControlData.isAnimating);

const [stars, setStars] = useState<Star[]>([]);

  type Star = {
    left: number;
    top: number;
    size: number;
  };

  useEffect(() => {

    if (isAnimationActive) {
    // Initialize the stars
    const initialStars = Array.from({ length: 350 }).map(() => ({
      left: Math.random() * window.innerWidth,
      top: Math.random() * (window.innerHeight+90), // adding some constant because the browser has some bar that is messing it up
      size: Math.random() * 3
    }));
    setStars(initialStars);
  
    
    // Function to update the position of a random star
    const moveRandomStar = () => {


      // Use the current state of stars
      setStars((currentStars) => {
        const updatedStars = [...currentStars];
  
        const numOfStarsToMove = Math.floor(Math.random() * 5) + 1; // Move between 1 to 10 stars
  
        for (let i = 0; i < numOfStarsToMove; i++) {
          const starIndex = Math.floor(Math.random() * updatedStars.length);
          const randomStar = updatedStars[starIndex];
  
          // Calculate new random positions
          const newLeft = randomStar.left + (Math.random() * 80 - 40);
          const newTop = randomStar.top + (Math.random() * 80 - 40);
  
          updatedStars[starIndex] = {
            ...randomStar,
            left: newLeft,
            top: newTop
          };
        }
  
        return updatedStars;
      });
    }


    const interval = setInterval(moveRandomStar, 500); // move a set of random stars every 0.5 seconds
  
    return () => clearInterval(interval);
    }    
  }, [isAnimationActive]);

  return (
    <div>
    {isSmallScreen ? (
      // Content for small screen devices
      <div>
        
        {<SmallScreenLanding/>}
      </div>
    ) : (
      // Content for other screen sizes
      <div className={styles.App}>



          <div className={styles['stars-background']}
           style={{ height: '100vh', width: '100vw',}}>
    
        <div style={{ position: 'relative', height: '100vh' }}>
          {stars.map((star, index) => (
            <div
              key={index}
              className={styles["star"]}
              style={{
                position: 'absolute',
                left: `${star.left}px`,
                top: `${star.top}px`,
                width: `${star.size}px`,
                height: `${star.size}px`,
                background: 'white',
                borderRadius: '50%',
                zIndex: -1,
              }}
            />
          ))}

      <Header isHomePage={false}/>
        {/* 
        <div className={styles['header-home-background']}>
          <Header isHomePage={true}/>
        </div>
        */}
        <div className={styles['home-create-a-crypto-title']}>

          <div style={{ opacity: opacity, marginTop: '160px' }}>
          <h2 style={{ fontSize: window.innerWidth > 1300 ? '110px' : 
          window.innerWidth > 950 ? '90px' : '80px'}}>Create A Crypto</h2>

        
            <div>
            {currentUserCreationDate ? (
              <CreateCryptoModals buttonType={'Begin'} buttonTextColor={'#222222'} buttonBackgroundColor={'#FFA14A'}/>
            ) : (
              <CreateCryptoModals buttonType={'tryItFree'} buttonTextColor={'#222222'} buttonBackgroundColor={'#FFA14A'}/>
            )}
          </div>
          </div>


        </div>
      </div>
      </div>


<div style={{ height: '100vh', backgroundColor: '#1a1a1a'}}>
<HomePadeDynamic/>
</div>


      <div style = {{background: '#1a1a1a', paddingBottom: '100px', paddingTop: '0px'}}>

      <div style={{
    display: 'flex',
    justifyContent: 'space-around', 
    flexWrap: 'wrap',
    paddingTop: '100px', 
    margin: '0 auto',
    maxWidth: '1000px'
}}>


<div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
<div>


<div style={{ position: 'relative', width: 'auto', height: '500px',
display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
  <img
    src={appTradeImage}
    alt="Trade"
    style={{
      position: 'absolute',
      width: 'auto',
      height: '500px',
      border: '2px solid #444444',
      borderRadius: '20px',
      opacity: tradeImageSelected ? 1 : 0,
    }}
  />
  <img
    src={appHomeImage}
    alt="Home"
    style={{
      position: 'absolute',
      width: 'auto',
      height: '500px',
      border: '2px solid #444444',
      borderRadius: '20px',
      opacity: homeImageSelected ? 1 : 0,
    }}
  />
  <img
    src={appCreateImage}
    alt="Create"
    style={{
      position: 'absolute',
      width: 'auto',
      height: '500px',
      border: '2px solid #444444',
      borderRadius: '20px',
      opacity: createImageSelected ? 1 : 0,
    }}
  />
</div>

</div>




<div style={{display: 'flex', height: '30px', alignItems: 'center', justifyContent: 'space-between'}}> 
<div style={{fontSize: '25px', cursor: 'pointer', 
color: createImageSelected ? '#FFA14A' : 'white',}}
onClick={handleCreateImageClicked}>Create</div>

<div style={{color: 'white', fontSize: '30px'}}>|</div>
<div style={{fontSize: '25px', cursor: 'pointer', 
color: tradeImageSelected ? '#FFA14A' : 'white',}}
onClick={handleTradeImageClicked}>Trade</div>
<div style={{color: 'white', fontSize: '30px'}}>|</div>
<div style={{fontSize: '25px', cursor: 'pointer', 
color: homeImageSelected ? '#FFA14A' : 'white'}}
onClick={handleHomeImageClicked}>Explore</div>
</div>
</div>

<div style={{background: '#1a1a1a', 
display: 'flex', justifyContent: 'center', 
alignItems: 'center', gap: '20px', }}>
  

<div style={{display: 'flex', flexDirection: 'column', height: '570px', justifyContent: 'space-between'}}>

<div style={{color:'white', background: '#1a1a1a',
fontWeight: 'bold', fontSize: '35px'}}>FinFriend on the go. Anywhere,<br/> anytime.</div>


<div style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
      <img
        src={qrCode}
        alt="qrCode"
        style={{
          width: '200px',
          height: '200px',
          border: '2px solid #444444',
          borderRadius: '20px'
        }}
      />
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px', height: '200px', justifyContent: 'center'}}>

        
      <div style={{color: '#BBBBBB'}}>Scan to dowload the App</div>
      <div style={{color: 'white', fontSize: '25px'}}>iOS</div>
      </div>
      </div>


<div style= {{display: 'flex', justifyContent: 'space-around'}}>

<a href="https://apps.apple.com/us/app/finfriend/id6446862715" 
    target="_blank" 
    rel="noopener noreferrer" 
    style={{ textDecoration: 'none' }}  // This gets rid of the underline
>
      <div
        style={{
            backgroundColor: isIOSHovered ? 'white' : '#1a1a1a',
            color: isIOSHovered ? '#1a1a1a' : 'white',
            border: '1px solid white',
            display: 'flex',
            justifyContent: 'center',  // Horizontally centers the content
            alignItems: 'center',  // Vertically centers the content
            padding: '10px 5px',
            fontSize: '25px',
            fontWeight: 'bold',
            textAlign: 'center',
            borderRadius: '10px',
            cursor: 'pointer',
            width: '220px',
        }}
        onMouseEnter={handleIOSMouseEnter}
        onMouseLeave={handleIOSMouseLeave}
    >
        <div style={{ display: 'flex', alignItems: 'center'  }}>
            <img
                src={appleIcon}
                alt="Google Play Icon"
                style={{
                    width: '40px', 
                    height: '40px',
                    filter: isIOSHovered ? 'invert(1)' : 'none',
                    
                    marginRight: '10px'
                }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                App Store
            </div>
        </div>
    </div>
</a>

{/*
    <div
        style={{
            backgroundColor: isAndroidHovered ? 'white' : '#1a1a1a',
            color: isAndroidHovered ? '#1a1a1a' : 'white',
            display: 'flex',
            justifyContent: 'center',  // Horizontally centers the content
            alignItems: 'center',  // Vertically centers the content
            padding: '10px 5px',
            fontSize: '25px',
            fontWeight: 'bold',
            textAlign: 'center',
            borderRadius: '10px',
            cursor: 'pointer',
            width: '220px',
        }}
        onMouseEnter={handleAndroidMouseEnter}
        onMouseLeave={handleAndroidMouseLeave}
    >
        <div style={{ display: 'flex', alignItems: 'center'}}>
            <img
                src={googlePlayIcon}
                alt="Google Play Icon"
                style={{
                    width: '40px', 
                    height: '40px',
                    filter: isAndroidHovered ? 'invert(1)' : 'none',
                    marginRight: '10px'
                }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                Google Play
            </div>
        </div>
    </div>
*/}
    
    </div>

      </div>
        </div>




</div>


<div style={{display: 'flex', flexDirection: 'column', height: '400px', 
        justifyContent: 'space-around', alignItems: 'center', marginTop: '250px', 
        backgroundColor: '#222222', width: '100vw', marginBottom: '150px', }}>

          <div style={{color: 'white', fontSize: '45px'}}>Own a stake in the future</div>
          <div style={{color: 'white', fontSize: '25px', textAlign: 'center'}}>
      Explore all of the assets people are working on in <br/> the 
      <span 
        onClick={() => {
          navigate('/browse-cryptos');
          window.scrollTo(0, 0); // Reset scroll position to the top of the page
        }}
        style={{ color: '#FFA14A', 
        cursor: 'pointer', 
        marginLeft: '7px',
        textDecoration: 'underline', textDecorationColor: '#FFA14A', }}>
        Markets
      </span>
      . Buy and sell and send them around!
    </div> 
          </div>

</div>





      <Footer />

    </div>
    )}


  </div>


  );
}

export default Home;
